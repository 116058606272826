import GroupRepository from '@/shared/http/repositories/access/group'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import GroupModel from '@/shared/models/group.js'
import GuidUtils from '@/shared/utils/guid'
import Roles from '@/shared/mixins/hasRoles'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
  },

  mixins: [Roles],

  data: () => ({
    group: new GroupModel(),
    isNew: null
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Grupos',
            href: this.$router.resolve({ name: 'Groups' }).href
          },
          {
            text: 'Gerenciar Grupo',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.clearForm()
    this.$validator.reset()

    this.isNew = !this.$route.params.id || GuidUtils.isEmpty(this.$route.params.id)
    if (!this.isNew) this.getById()
  },

  methods: {
    onSave() {
      this.$validator.validateAll().then(isValid => {
        if(!isValid) {
          return toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
        if(this.isNew) {
          return this.create()
        }

        this.update()
      })
    },

    create() {
      loading.push()
      GroupRepository.Create(this.group)
        .then(res => {
          if(!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Grupo criado com sucesso!', 'CRIAR GRUPO')
          Promise.resolve()
          return this.$router.replace({ name: 'Groups' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar grupo', 'ERRO')
        })
    },

    update() {
      loading.push()
      GroupRepository.Update(this.group)
        .then(res => {
          if(!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Grupo atualizado com sucesso!', 'ATUALIZAR GRUPO')
          Promise.resolve()
          return this.$router.replace({ name: 'Groups' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao atualizar grupo', 'ERRO')
        })
    },

    getById() {
      loading.push()
      GroupRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.group = new GroupModel(res.data.data)

          loading.pop()
          this.$validator.reset()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar grupo', 'ERRO')
        })
    },

    clearForm() {
      this.group = new GroupModel()
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    backTo() {
      this.$router.go(-1)
    }
  }
}
